.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: "Montserrat";
    color: #fef6f3;
    width: 90%;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 42px;
    border: 1px solid #fff;
    padding-top: 32px;
    border-radius: 8px;

    @media screen and (min-width: 1024px) {
        font-size: 20px;
        width: 95%;
    }

    .container-dice {

    }

    &__header {
        font-family: "Megrim";
        font-size: 30px;
        text-align: center;
        border: #ced4da 2px solid;
        @media screen and (min-width: 1024px) {
            font-size: 40px;
        }
    }

    &__description {
        font-family: "Montserrat";
        text-align: left;
    }

    &__container {
        display: flex;
        gap: 8px;
        &__sub {
            display: flex;
            flex-direction: row;
            gap: 8px;

            & i {
                color: #ced4da;
            }
            &__title {
                font-weight: 600;
                color: #ced4da;
                white-space: nowrap;
            }

            &__desc {
            }
        }
    }
}
