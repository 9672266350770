.profilePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    &__title {
        font-family: "Cinzel Decorative";
        font-size: 32px;
        color: #fef3f6;
        margin: 0;
        text-align: center;
        @media screen and (min-width: 1024px) {
            font-size: 48px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
        width: 80%;
        margin: 0 auto;
        @media screen and (min-width: 1024px) {
            width: 50%;
        }
        &__button {
            font-family: "Cinzel Decorative";
            font-size: 16px;
            color: #232323;
            font-weight: 600;
            background-color: #fef3f6;
            border-radius: 8px;
            border: #232323 solid 1px;
            padding: 8px;
            width: 200px;
            align-self: center;
            box-shadow: 1px 4px 4px #000000;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
            @media screen and (min-width: 1024px) {
                font-size: 24px;
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            &__title {
                font-family: "Cinzel Decorative";
                font-size: 16px;
                font-weight: 600;
                color: #fef3f6;
                margin: 0;

                text-align: center;
                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                }
            }

            &__input {
                font-family: "Montserrat";
                font-size: 14px;
                color: #232323;
                background-color: #fef3f6;
                border: none;
                border-radius: 8px;
                padding: 6px;
                width: 250px;
                align-self: center;
                box-shadow: 1px 4px 4px #000000;

                &:hover {
                    transform: scale(1.1);
                }
                @media screen and (min-width: 1024px) {
                    font-size: 16px;
                }
            }

            &__data {
                text-align: center;
                font-family: "Montserrat";
                font-size: 14px;
                color: #fef3f6;
                margin: 0;
                @media screen and (min-width: 1024px) {
                    font-size: 16px;
                }
            }
        }
    }
}
