.navDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px 20px 0px;
    gap: 8px;
    &__title {
        font-size: 32px;
        font-family: "Montserrat";
        font-weight: 900;
        color: #fef6f3;
        border: #fef6f3 2px solid;
        padding: 8px;
        text-align: center;
    }
    &__links {
        display: flex;
        gap: 16px;

        &__link {
            text-decoration: none;
            font-size: 20px;
            color: #fef6f3;
            font-family: "Montserrat";
            &:hover {
                cursor: pointer;
                color: white;
                transform: scale(1.1);
            }
        }
        &__button {
            background: #A7001E;
            box-shadow: 0 0 25px #A7001E;
            border: none;
            font-size: 20px;
            color: #fef6f3;
            border-radius: 8px;
            font-family: 'Montserrat';

            &:hover {
                cursor: pointer;
                box-shadow: 0 0 5px #A7001E,
                0 0 25px #A7001E,
                0 0 50px #A7001E,
                0 0 100px #A7001E;
                transform: scale(1.05);

            }
        }
    }
}
