.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    gap: 8px;

    &__paragraph {
        font-family: 'Calligraffitti';
        line-height: 2.5;
        color: #fef6f3;
        text-align: center;
        width: 80%;
        font-weight: 600;
        margin-bottom: 50px;
        margin-top: 10px;
        line-height: 1.5;
        font-size: 16px ; 
        letter-spacing: 0.65px;
        animation: blink 0.75ms step-end infinite;
        animation: blink-caret 0.75s step-end infinite !important;
    
        @media screen and (min-width: 1024px) {
          margin-bottom: 10px;
            width: 50%;
            line-height: 1.5;
            font-size: 24px;

        }
    }
    
}

@keyframes blink {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #fef6f3;
    }
  }
  
  
  
  