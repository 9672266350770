main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.dice-wrapper {
  display: flex;
  gap: 20px;
}

.dice-container {
  perspective: 1000px;
}

.dice {
  width: 60px;
  height: 60px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1.5s ease-in-out;

  &.rolling {
    animation: rotate 0.1s infinite linear;
  }

  > div {
    height: 60px;
    width: 60px;
    position: absolute;
    background: #fff;
  }

  span {
    width: 8px;
    height: 8px;
    background: black;
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  .front {
    transform: rotateY(0deg) translateZ(30px);

    span {
      &:nth-child(1) {
        top: 10px;
        left: 12px;
      }
      &:nth-child(2) {
        top: 10px;
        right: 12px;
      }
      &:nth-child(3) {
        top: 26px;
        left: 12px;
      }
      &:nth-child(4) {
        top: 26px;
        right: 12px;
      }
      &:nth-child(5) {
        bottom: 10px;
        left: 12px;
      }
      &:nth-child(6) {
        bottom: 10px;
        right: 12px;
      }
    }
  }

  .back {
    transform: rotateX(180deg) translateZ(30px);

    span {
      top: 26px;
      left: 26px;
    }
  }

  .right {
    transform: rotateY(90deg) translateZ(30px);

    span {
      &:nth-child(1) {
        top: 12px;
        left: 12px;
      }
      &:nth-child(2) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(3) {
        top: 26px;
        left: 26px;
      }
      &:nth-child(4) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(5) {
        bottom: 12px;
        right: 12px;
      }
    }
  }

  .left {
    transform: rotateY(-90deg) translateZ(30px);

    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        bottom: 12px;
        left: 12px;
      }
    }
  }

  .top {
    transform: rotateX(90deg) translateZ(30px);

    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(3) {
        bottom: 26px;
        left: 26px;
      }
    }
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(30px);

    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        top: 12px;
        left: 12px;
      }
      &:nth-child(3) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(4) {
        bottom: 12px;
        right: 12px;
      }
    }
  }
}

.button {
  bottom: 20px;
  background: #fef6f3;
  padding: 16px 8px;
  border-radius: 4px;
  color: #272727;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-family: "Montserrat";
  font-weight: 600;

  &:hover {
    background: darken(#fef6f3, 10%);
  }
}

.stuck-step {
  text-align: center;
  margin-bottom: 8px;
  .link {
    color: rgb(81, 81, 243);
  }
}

@keyframes rotate {
  0% { transform: rotateX(0deg) rotateY(0deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
}
