.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__clef {
        background-image: url("../../assets/cleftexture.webp");
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        animation: clef 5s infinite linear;
        height: 300px;
        background-size: cover;
    }
    &__loading {
        color: #fef6f3;
        font-family: "Montserrat";
        font-size: 24px;
        font-weight: 600;
    }

    &__subloading {
        color: #fef6f3;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
    }
}

@keyframes clef {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}
