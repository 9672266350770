.post {

    &__sub {
        display: flex;
        &__title {
            font-family: "Cinzel Decorative";
            margin: 0px 10px 0px 10px;
            font-size: 22px;
            white-space: nowrap;
            @media screen and (min-width: 1024px) {
                font-size: 28px;
                // width: 3 0%;
            }
        }
        &__barre {
            background-color: #fef3f6;
            width: 100%;
            height: 1px;
            border-radius: 5px;
            margin-right: 10px;
            align-self: center;
        }
    }
    .postTop {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            gap: 20px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;

        &__enigme {

            &__images {
                display: flex;
                justify-content: center;
                margin-bottom: 30px;

                &__imageText {
                    width: 90%;
                    max-width: 600px;
                }
            }
            &__container {
                margin-bottom: 50px;
                display: flex;
                justify-content: center;

                &__form {
                    display: flex;
                    justify-content: center;
                    margin-right: 10px;
                    gap: 4px;

                    &__input {
                        color: #232323;
                        width: 200px;
                        height: 40px;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: 1px 4px 4px #000000;
                        border: none;
                        padding-left: 10px;
                        background-color: #fef6f3;
                        font-family: "Work Sans";
                        @media screen and (min-width: 1024px) {
                            width: 400px;
                            height: 50px;
                            border-radius: 8px;
                            box-shadow: 1px 4px 4px #000000;
                        }
                    }

                    &__button {
                        width: 40px;
                        height: 42px;
                        border-radius: 8px;
                        border: none;
                        background-color: #fef6f3;
                        color: #232323;
                        box-shadow: 1px 4px 4px #000000;
                        font-weight: 700;

                        @media screen and (min-width: 1024px) {
                            width: 60px;
                            height: 52px;
                            border-radius: 8px;
                            box-shadow: 1px 4px 4px #000000;
                        }
                        &__icon {
                            color: #232323;
                            font-size: 20px;
                        }
                        &__icon:hover {
                            color: #10002b;
                            cursor: pointer;
                        }
                    }
                }

                &__reponse {
                    margin: 0 auto;
                    font-size: 16px;
                    font-family: "Special Elite";
                    font-weight: 500;
                    width: 90%;
                    margin-top: 10px;
                    text-align: justify;
                    line-height: 1.1em;

                    @media screen and (min-width: 1024px) {
                        font-size: 18px;
                        width: 80%;
                        margin: auto;
                    }

                    &__clef {
                        margin: 0 auto;
                        font-size: 16px;
                        font-family: "Special Elite";
                        font-weight: 500;
                        width: 90%;
                        margin-top: 10px;
                        text-align: center;
                        line-height: 1.1em;
                    }
                }
            }
            &__sub {
                display: flex;
                &__title {
                    font-family: "Cinzel Decorative";
                    margin: 0px 10px 0px 10px;
                    font-size: 22px;
                    white-space: nowrap;
                    // width: 30%;
                    @media screen and (min-width: 1024px) {
                        font-size: 28px;
                        // width: 3 0%;
                    }

                    &::hover {
                        cursor: pointer;
                    }
                }

                &__barre {
                    background-color: #fef3f6;
                    width: 100%;
                    height: 1px;
                    border-radius: 5px;
                    margin-right: 10px;
                    align-self: center;
                }
            }

            &__desc {
                font-family: "Work Sans";
                font-size: 18px;
                font-weight: 500;
                color: #fef3f6;
                padding: 5px;
                width: 90%;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 20px;
                @media screen and (min-width: 1024px) {
                    font-size: 26px;
                    width: 80%;
                    text-align: center;
                }
            }
        }

        &__lieu {
            width: 100%;
            @media screen and (min-width: 1024px) {
            }
            &__title {
                display: flex;
                gap: 4px;
                background: none;
                text-align: left;
                color: #fef3f6;
                padding: 5px;
                border: none;
                margin-bottom: 0;
                font-family: "Cinzel Decorative";
                font-weight: 600;
                font-size: 22px;
                letter-spacing: 0.1em;


                &:active {
                    background-color: #fef3f6;
                    border-radius: 8px;
                    color: #232323;
                }
            }

            &__desc {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-family: "Work Sans";
                font-size: 22px;
                font-weight: 500;
                margin: 0 auto;
                color: #fef3f6;
                padding: 5px;
                width: 90%;
                opacity: 0;
                animation: fadeInDown 0.5s ease forwards;
                text-align: justify;

                &__images {

                    &__img {
                        width: 100%;
                        max-width: 600px;

                        @media screen and (min-width: 1024px) {
                            width: 100%;
                            max-width: 800px;
                        }
                    }
                }

                @media screen and (min-width: 1024px) {
                    font-size: 18px;
                    margin: 0;
                    width: 80%;
                }
            }
        }
        // &__sub {
        //     display: flex;
        //     &__title {
        //         font-family: "Cinzel Decorative";
        //         margin: 0px 10px 0px 10px;
        //         font-size: 20px;
        //         white-space: nowrap;
        //         // width: 30%;
        //         @media screen and (min-width: 1024px) {
        //             font-size: 28px;
        //             // width: 3 0%;
        //         }
        //     }
        //     &__barre {
        //         background-color: #fef3f6;
        //         width: 100%;
        //         height: 1px;
        //         border-radius: 5px;
        //         margin-right: 10px;
        //         align-self: center;
        //     }
        // }

        &__under {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__maps {
                align-self: center;
                width: 150px;
                height: 30px;
                border: none;
                border-radius: 4px;
                font-family: 'Montserrat';
                color: #272727;
                font-weight: 500;
                align-items: center;
                display: flex;
                justify-content: center;

                &::before {
                    content: "";
                    display: inline-block;
                    background-image: url("../../assets/maps.png");
                    background-size: cover;
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #fef6f3;
                }
            }
            &__button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;
                margin-left: auto;
                margin-right: auto;

                @media screen and (min-width: 768px) {
                    gap: 50%;
                    margin-bottom: 30px;
                }

                @media (max-width: 500px) {
                    flex-direction: column;
                }
            &__next {
                font-size: 18px;
                font-family: "Montserrat";
                color: #232323;
                font-weight: 600;
                width: 150px;
                padding: 6px;
                border-radius: 4px;
                border: none;
                margin: 10px;

                @media screen and (min-width: 768px) {
                    font-size: 24px;
                    width: 200px;
                    padding: 10px;
                }

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);

                }
            }
        }

            &__desc {
                font-family: "Cinzel Decorative";
            }
            &__code {
                font-family: "Megrim";
                font-size: 2.5rem;
                font-weight: 600;
                color: #fef3f6;
                width: 50px;
                border-radius: 5px;
                margin-left: 10px;
                margin-bottom: 10px;
            }
        }

        &__images {
            margin-top: 10px;
            @media screen and (min-width: 768px) {
                width: 600px;
                margin-left: 15px;
            }
            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
                max-width: 600px;
            }
        }
    }
}

.post__group {
    display: flex;
    margin-bottom: 15px;
    &__code {
        font-family: "Megrim";
        margin: 0px 10px 0px 10px;
        font-size: 28px;
        font-weight: 600;
        white-space: nowrap;
        // width: 30%;
        @media screen and (min-width: 1024px) {
            font-size: 32px;
            // width: 3 0%;
        }

        &::hover {
            cursor: pointer;
        }
    }

    &__barre {
        background-color: #fef3f6;
        width: 100%;
        height: 1px;
        border-radius: 5px;
        margin-right: 10px;
        align-self: center;
    }
}

.glow {
    // ecarte entre les saut de ligne
    line-height: 16px;
    animation: glowing 2s infinite;
}

@keyframes glowing {
    0% { text-shadow: 0 0 5px #fff; }
    50% { text-shadow: 0 0 20px #fff; }
    100% { text-shadow: 0 0 5px #fff; }
  }

@keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .break-line {
    white-space: normal;
  }

  .doubleNext {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    p {
        display: flex;
        justify-content: center;
    }
}