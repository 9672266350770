* {
    margin: 0;
    // color: #fef6f3;
}

.select {
    color: #232323;
}

body { 
    background-color: #232323;
    color: white
}