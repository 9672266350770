.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
    }
    
    &__annexe {
        @media screen and (min-width: 768px) {
            display: flex;
            width: 30%;
            justify-content: space-around;

        }

        &__image {
            width: 90%;
            max-width: 400px;
            @media screen and (min-width: 1024px) {
                width: 90%;
                max-width: 400px;
            }
    }
}
}
