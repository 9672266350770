.homePage {
        
        text-align: center;
        font-family: 'Cinzel Decorative';
        font-size: 26px;
        color: #fef6f3;
        text-align: center;
        width: 70%;
        margin-bottom: 60px;

        @media screen and (min-width: 1024px) {
            width: 70%;
            font-size: 40px;
        }
}

.homePageContainer {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &__title {
        font-family: 'Cinzel Decorative';
        font-size: 20px;
        color: #fef6f3;
        text-align: center;
        width: 70%;

        @media screen and (min-width: 1024px) {
            width: 70%;
            font-size: 40px;
        }
    }

    &__headerContainer {
        display: flex;
        gap: 16px;

        @media screen and (min-width: 768px) {
            gap: 32px;
            
        }


    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
        height: 70px;
        background-color: #fef6f3;
        box-shadow: 1px 4px 4px #000000;
        padding: 12px;
        gap: 8px;
        border-radius: 8px;
        justify-content: center;
        color: #232323;

        @media screen and (min-width: 768px) {
            width: 100px;
            height: 90px;   
        }
        

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }

        &__title {
            font-size: 12px;
            white-space: nowrap;
            font-size: 16px;
            font-family: "Megrim";
            font-weight: 800;

            @media screen and (min-width: 768px) {
                font-size: 20px;
                
            }
        }
        &__icone {
            font-size: 40px;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }
    }
}
}

.animate-opacity {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  