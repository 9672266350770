$form-bg-color: #232323;
$form-border-color: #e0e0e0;
$input-border-color: #d0d0d0;
$label-color: #4a4a4a;
$button-bg-color: #4a90e2;
$button-hover-color: #3b78c4;
$select-option-bg-color: #f8f8f8;

.checkbox-align {
  display: flex;
  align-items: center;

  &__label {
    margin-top: 9px;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  background-color: $form-bg-color;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;

  div {
    margin-bottom: 5px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: $label-color;
    color: white;
  }

  input[type="text"],
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    margin-top: 5px;
  }

  .Select {
    .select__control {
      border-color: $input-border-color;
    }
    .select__option {
      background-color: $select-option-bg-color;
    }
  }

  button {
    background-color: $button-bg-color;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 50px;
    &:hover {
      background-color: $button-hover-color;
    }
  }
}


.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
}

.toggle-switch .toggle-input {
  display: none;
}

.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: rgb(204, 10, 10);;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #4CAF50;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}
