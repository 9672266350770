.login {
    &__container {
        background-color: #232323;
        display: flex;
        flex-direction: column;
        &__title {
            font-family: "Cinzel Decorative";
            text-align: center;
            font-size: 46px;
            color: #fef6f3;
            @media screen and (min-width: 1024px) {
                font-size: 40px;
            }
        }
        &__form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            &__infos {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                width: 30%;

                & input {
                    font-family: "Montserrat";
                    color: black;
                    width: 200px;
                    height: 40px;
                    border-radius: 8px;
                    box-shadow: 1px 4px 4px #000000;
                    border: none;
                    background-color: #fef6f3;
                }
                & label {
                    font-family: "Montserrat";
                    font-size: 14px;
                    color: #fef6f3;
                }
            }
            &__button {
                font-family: "Montserrat";
                font-weight: 700;
                width: 100px;
                height: 40px;
                border-radius: 8px;
                box-shadow: 1px 4px 4px #000000;
                border: none;
                background-color: #fef6f3;
                // margin-bottom: 50px;
            }
            &__button:hover {
                cursor: pointer;
            }
        }
    }
}
