.ReactModal__Overlay {
    transform: translateY(100%);
    transition: transform 0.5s ease;
}

.ReactModal__Overlay--after-open {
    transform: translateY(0);
}

.ReactModal__Content--after-open {
    inset: 50% auto 0px !important;

}

.ReactModal__Overlay--before-close {
    transform: translateY(100%);
}
.navbar {
    z-index: 999;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    background-color: #fef6f3;
    @media screen and (min-width: 1024px) {
        position: relative;
        bottom: auto;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 1024px) {
            margin-right: 8px;
            gap: 16px;
        }
        &__mobile-button {
            background: none;
            border: none;
            width: 30%;
            padding: 5px;
            @media screen and (min-width: 1024px) {
                display: none;
            }

            &__icon {
                font-size: 30px;
                color: #232323;
            }
        }

        &__desktop {
            @media screen and (min-width: 1024px) {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }
            &__links {
                display: flex;
                gap: 16px;
                &-button {
                    display: none;
                    text-decoration: none;
                    color: #232323;
                    font-size: 20px;
                    font-weight: 700;
                    font-family: "Montserrat";
                    @media screen and (min-width: 1024px) {
                        display: block;
                    }
                    &:hover {
                        cursor: pointer;
                        color: #fef6f3;
                        text-shadow: 0 0 10px #232323, 0 0 20px #232323, 0 0 40px #232323, 0 0 80px #232323,
                            0 0 90px #232323, 0 0 100px #232323, 0 0 150px #232323;
                        transform: scale(1.1);
                    }
                }
            }

            &-logo {
                margin-left: 12px;
                display: none;
                background-image: url("../../assets/talesandkeynt.webp");
                background-repeat: no-repeat;
                width: 70px;
                height: 70px;
                background-size: cover;
                @media screen and (min-width: 1024px) {
                    display: block;
                }
            }
        }
    }
}
