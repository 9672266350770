.switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }
  
  .switch input {
    display:none;
  }
  
  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  
  .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
  }
  
  input:checked + .slider {
    background-color: #66bb6a;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

//Annexe
.modal__header {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-right: 50px;
 
    &__button__switch {
        width: 30%;
        max-width: 200px;
        border: transparent;
        border-radius: 4px;
        background: rgb(19, 19, 19);
        color: #fef6f3;
        font-size: 16px;  
        font-family: 'Cinzel Decorative';
        font-weight: 600;
        opacity: 90%;

        &:hover {
            opacity: 100%;
            cursor: pointer;
            transform: scale(1.1);
        }

        &:active {
            background-color: #fef3f6;
            color: #232323;   
        }
    }
}

