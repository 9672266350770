.header {
    background-color: #232323;
    margin-top: 30px;

    &__logo {
        display: flex;
        justify-content: center;
        // position: relative;

        &__img {
            background-image: url("../../assets/title.webp");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 150px;
            height: 150px;
            margin-bottom: 15px;

            @media screen and (min-width: 1024px) {
                width: 300px;
                height: 300px;
                margin-bottom: 30px;
            }
        }
    }
    &__title {
        font-family: "Megrim";
        font-size: 70px;
        color: #fef6f3;
        text-align: center;
        position: relative;
        @media screen and (min-width: 1024px) {
            font-size: 120px;
        }
    }
    &__subtitle {
        font-family: "Megrim";
        font-size: 50px;
        color: #fef6f3;
        text-align: center;
        position: relative;
        bottom: 25px;
        @media screen and (min-width: 1024px) {
            bottom: 45px;
            font-size: 100px;
        }
    }

    &__group {
        display: flex;
        justify-content: center;
        &__label {
            font-size: 22px;
            font-weight: 900;
            font-family: "Cinzel Decorative";
            // abaisser le texte
            position: relative;
            top: 5px;
            right: 50px;
            // faire un degrader de couleur dans le texte qui se dirige de haut en bas en blanc vers noir
            background: linear-gradient(0deg, #232323 0%, #fef6f3 30%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media screen and (min-width: 1024px) {
                font-size: 30px;
                right: 200px;
            }
            
        }
    }
    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        gap: 4px;

        &__input-code {
            color: #232323;
            width: 200px;
            height: 40px;
            border-radius: 8px;
            box-shadow: 1px 4px 4px #000000;
            border: none;
            padding-left: 10px;
            background-color: #fef6f3;
            font-family: "Work Sans";
            @media screen and (min-width: 1024px) {

                width: 550px;
                height: 55px;
                      
            }
        }
        &__button-submit {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            border: none;
            background-color: #fef6f3;
            color: #232323;
            box-shadow: 1px 4px 4px #000000;
            font-weight: 700;

            @media screen and (min-width: 1024px) {

                width: 60px;
                height: 55px;
                
            }  
            & FontAwesome {
                color: #232323;
                font-size: 20px;
            }
            & i:hover {
                color: #10002B;
                cursor: pointer;
            }
        }
        &__error {
            margin-top: 2.5%;
            text-align: center;
            position: relative;
            bottom: 15px;
            color: rgb(204, 10, 10);
            font-size: 16px;
            font-weight: 400;
            font-family: "Montserrat";

            @media  screen and (min-width: 768px) {
                bottom: 30px;
                font-size: 20px;
            }
        }
    }
}
