.container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    // background-color: #fcfcfc;
  }

  .card {
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    width: 350px;
    // height: 210px;
    background-color: #ffffff;
    padding: 4px 8px;

  }

  .card h3 {
    font-size: 22px;
    font-weight: 600;
  }

  .drop_box {
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
    padding-bottom: 50px;

  }

  .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
  }

  .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
  }

  .btn {
    text-decoration: none;
    background-color: #005af0;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
  }

  .btn:hover{
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
  }
  .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
  }


.modalcrud {
    width: 100%;

    @media screen and (min-width: 768px) {
        width: 90%;
        margin-top: 70px;
        justify-content: center;
        margin-left: 4.5%;
        height: 1000px;
    }
}

.crud {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {

        &__top {
            display: flex;
            justify-content: center;
            gap: 8px;

        }

        &__mid {
            display: flex;
            justify-content: center;
            gap: 8px;
        }

        &__bot {
            display: flex;
            justify-content: center;
            gap: 8px;
        }
    }

}

.crudContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    margin-bottom: 50px;


    @media screen and (min-width: 768px) {
        max-height: 300px;
    }

    &__title {
        text-align: center;
    }
    &__container {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            // gap: 150px;

        }
    &__images {
        display: flex;
        flex-direction: column;
        // mettre un espace entre les elements enfant qui permettent qu'un soit tout en haut et l'autre en bas
        justify-content: space-between;
        @media screen and (min-width: 1024px) {
            height: 500px;
            margin: 4px;
        }

        &__image {
            width: 300px;
        }

        &__delete {
            // bordure radius de 4px juste en bas
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #fef6f3;
            border: #232323;
            padding: 8px;
            font-size: 16px;
            color: #232323;

            &:hover {
                cursor: pointer;
            }

            &:hover > i {
                cursor: pointer;
                color: #fef6f3;
                text-shadow: 0 0 10px #232323, 0 0 20px #232323, 0 0 40px #232323, 0 0 80px #232323, 0 0 90px #232323,
                    0 0 100px #232323, 0 0 150px #232323;
                transform: scale(1.5);
            }
        }
    }
}

    &__pagination {
        display: flex;
        margin: 8px 0px 8px 0px;
        gap: 4px;
        &__number {
            background: none;
            border: none;
            font-family: 'Montserrat';
            font-size: 16px;
            color: #fef6f3;
            font-weight: 600;
            opacity: 80%;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
                opacity: 100%;
            }
        }
        &__button {
            background-color: #fef6f3;
            border: #232323 1px solid;
            border-radius: 4px;
            border:#232323;
            color: #232323;
            font-size: 16px;
            font-family: 'Montserrat';
            font-weight: 600;
            &:hover {
                cursor: pointer;
                background-color: #232323;
                color: #fef6f3;
                border: #fef6f3 1px solid;
            }
        }
    }

    &__titles {
        display: flex;
        justify-content: space-between;
        padding: 0px 8px 0px 8px;
        background-color: #fef6f3;
        width: 280px;
        height: 40px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &__title {
            align-self: center;
            font-size: 24px;
            font-family: "Montserrat";
            font-weight: 600;
            color: #232323;
        }
        &__button {
            background: transparent;
            border: none;
            font-size: 20px;
            &:hover {
                cursor: pointer;
                color: #09be21;
                text-shadow: 0 0 10px #232323, 0 0 20px #232323, 0 0 40px #232323, 0 0 80px #232323, 0 0 90px #232323,
                    0 0 100px #232323, 0 0 150px #232323;
                transform: scale(1.1);
            }
        }
    }

    &__main {
        background-color: #fef6f3;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        width: 280px;
        height: 30px;
        padding: 4px 8px 4px 8px;

        &__title {
            color: #232323;
            font-family: 'Montserrat';
            font-weight: 600;
        }
        &__sub {
            display: flex;
            gap: 4px;

            &__edit {
                background: transparent;
                border: none;
                font-size: 16px;
                align-items: center;
                &:hover {
                    cursor: pointer;
                    color: rgb(255, 196, 0);
                    text-shadow: 0 0 10px #232323, 0 0 20px #232323, 0 0 40px #232323, 0 0 80px #232323, 0 0 90px #232323,
                        0 0 100px #232323, 0 0 150px #232323;
                    transform: scale(1.1);
                }
            }
            &__delete {
                background: transparent;
                border: none;
                font-size: 16px;
                align-items: center;
                &:hover {
                    cursor: pointer;
                    color: #ff0000;
                    text-shadow: 0 0 10px #232323, 0 0 20px #232323, 0 0 40px #232323, 0 0 80px #232323, 0 0 90px #232323,
                        0 0 100px #232323, 0 0 150px #232323;
                    transform: scale(1.1);
                }
            }
        }
    }
}

.active {
    opacity: 100%;
    transform: scale(1.2);
}

.post__container__under__maps {
    width: fit-content;
}