.index__top {
    margin-bottom: 50px;
}
.index__header {
    display: flex;
    margin-top: 16px;
    
    &__code {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Megrim';
        width: 50%;
    }

    &__lieu {
        font-size: 20px;
        font-weight: 700;
        font-family: 'Megrim';
        width: 100%;
    }
}

.index__container {
    width: 90%;
    @media screen and (min-width: 768px) {
        width: 95%;
    
        
    }
    &__subcontainer {
        display: flex;
        justify-content: space-between;
        &__code {
            width: 50%;
            padding: 5px;
            font-family: 'Cinzel Decorative';
        }
    
        &__title {
            width: 100%;
            padding: 5px;
            font-family: 'Cinzel Decorative';

        }
    }
}
